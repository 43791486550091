import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrush } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from "@mui/material/ToggleButton";
import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "../../../context/SnackbarProvider";

export default function ButtonCopy(props) {
  const { editor } = props;
  const [copiedFormat, setCopiedFormat] = useState(null);
  const [isBrushActive, setIsBrushActive] = useState(false);
  const { showSnackbar } = useSnackbar();
  const copyFormatter = () => {
    if (!editor) return;

    const { from, to } = editor.state.selection;

    if (from === to) {
      showSnackbar({
        neutral: true,
        message: `Necessário selecionar o elemento a ser copiado.`,
        timestamp: true,
      });
      return;
    }

    const marks =
      editor.state.storedMarks || editor.state.selection.$from.marks();

    const nodeType = {
      name: editor.state.selection.$from.parent.type.name,
      level: editor.state.selection.$from.parent.attrs.level,
    };
    if ((!marks || marks.length === 0) && !nodeType) {
      showSnackbar({
        erro: true,
        message: `Nenhum elemento para cópia de formatação encontrado.`,
        timestamp: true,
      });
      return;
    }

    setCopiedFormat({ marks, nodeType });
    setIsBrushActive(true);
    showSnackbar({
      success: true,
      message: `Formatação copiada! Selecione outro texto para aplicar.`,
      timestamp: true,
    });
  };

  const applyFormatting = () => {
    if (!editor || !copiedFormat) return;
    const { marks, nodeType } = copiedFormat;

    const allMarks = editor.extensionManager.extensions
      .filter((ext) => ext.type === "mark")
      .map((ext) => ext.name);

    allMarks.forEach((mark) => {
      editor.chain().focus().unsetMark(mark).run();
    });

    if (nodeType) {
      const level = nodeType?.level ? { level: nodeType.level } : {};
      editor.chain().focus().setNode(nodeType.name, level).run();
    }
    marks.forEach((mark) => {
      editor.chain().focus().setMark(mark.type, mark.attrs).run();
    });

    setIsBrushActive(false);
    showSnackbar({
      success: true,
      message: `Formatação aplicada.`,
      timestamp: true,
    });
  };

  return (
    <Tooltip
      title={isBrushActive ? "Aplicar formatação" : "Copiar formatação"}
      placement="top"
    >
      <ToggleButton
        onClick={isBrushActive ? applyFormatting : copyFormatter}
        sx={{
          width: 40,
          height: 40,
          marginRight: 1,
          color: isBrushActive ? "green" : "",
          borderColor: isBrushActive ? "green" : "#888",
        }}
        value="Limpar formatação"
      >
        <FontAwesomeIcon icon={faBrush} />
      </ToggleButton>
    </Tooltip>
  );
}
