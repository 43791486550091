import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../components/InputComponent/InputComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import CheckboxComponent from "../../components/CheackboxComponent/CheckboxComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import GenericService from "../../services/GenericService";
import { getUserInfoFromToken } from "../../services/Auth";
import { useSnackbar } from "../../context/SnackbarProvider";
import DataPickerComponent from "../../components/DataPickerComponent/DataPickerComponent";

export const searchLoader = async () => {
  const user = getUserInfoFromToken();
  if (!user) {
    return false;
  }
  const teamNumber = user.team.replace("RF", "");
  try {
    const responsePesquisador = await GenericService.findAllList(
      `team/investigate/${teamNumber}`,
    );
    const responseSupervisor = await GenericService.findAllList(
      `team/supervisor/${teamNumber}`,
    );
    const responseTiposRelatorio =
      await GenericService.findAllList("type-report");
    const responseTeams = await GenericService.findAllList("teams");

    return {
      pesquisadorData: responsePesquisador.data,
      supervisorData: responseSupervisor.data,
      tiposRelatorio: responseTiposRelatorio.data,
      teams: responseTeams.data,
    };
  } catch (error) {
    return {
      pesquisadorData: [],
      supervisorData: [],
      tiposRelatorio: [],
      teams: [],
      error:
        "Houve um erro ao carregar os dados. Por favor, tente novamente mais tarde.",
    };
  }
};

const newReportFormValidationSchema = yup.object({
  title: yup.string().required("O campo ”título” não pode ficar vazio"),
  researcher: yup
    .object()
    .required("O campo ”pesquisador” não pode ficar vazio"),
  supervisor: yup
    .object()
    .required("O campo ”supervisor” não pode ficar vazio"),
  ovr: yup.string().required("O campo ”OVR” não pode ficar vazio"),
  typeReporting: yup
    .object()
    .required(`O campo "tipo de relatório" não pode ficar vazio`),
  confidential: yup.boolean(),
  justification: yup.string(),
});

export default function SearchReport() {
  const loaderData = useLoaderData();
  const { pesquisadorData } = loaderData;
  const { supervisorData } = loaderData;
  const { tiposRelatorio } = loaderData;
  const { teams } = loaderData;
  const currentDate = new Date().toLocaleDateString("pt-BR");
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(newReportFormValidationSchema),
  });
  const navigate = useNavigate();
  const userInfo = getUserInfoFromToken();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleNewReport = async (data) => {
    reset();
    const teamUser = teams.find((team) => team.name === userInfo.team);
    try {
      const payload = {
        ...data,
        conclusionDate: "",
        addOns: "",
        linkBucket: userInfo.team,
        team: {
          id: teamUser ? teamUser.id : null,
        },
        status: {
          id: 1,
        },
        modelReporting: {
          id: 1,
        },
      };
      const response = await GenericService.create("reporting-order", payload);
      // eslint-disable-next-line no-constant-condition
      if (response.status === 200 || 500) {
        showSnackbar({
          success: true,
          message: "Relatório criado com sucesso!",
          timestamp: true,
        });
        navigate("/relatorios");
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao criar o relatório: ${error}`,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNewReport)}>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          marginY={5}
        >
          <Typography variant="h4" alignSelf="center">
            Pesquisar relatórios
          </Typography>

          <Box display="flex" gap={2}>
            <ButtonComponent title="Pesquisar" icon="search" submit />
          </Box>
        </Box>
        <Grid container display="flex" gap={3} spacing={2}>
          <Grid container xs={8} padding={2} spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Título"
                    placeholder="Digite o título desejado..."
                    type="edit"
                    maxLength={100}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="researcher"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Pesquisador"
                    placeholder="Digite o nome do pesquisador..."
                    options={pesquisadorData}
                    type="user"
                    selectHelperText={error?.message}
                    disableArrow
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="supervisor"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Supervisor"
                    placeholder="Digite o nome do supervisor..."
                    type="supervisor"
                    options={supervisorData}
                    selectHelperText={error?.message}
                    disableArrow
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="teamFilter"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    label="Equipe"
                    placeholder="Selecionar..."
                    type="users"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="typeReporting"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Tipo"
                    placeholder="Selecione o tipo de relatório..."
                    options={tiposRelatorio}
                    selectHelperText={error?.message}
                    type="type"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="space-between">
                <Box mr={1} flexGrow={1}>
                  <Controller
                    name="firstDate"
                    control={control}
                    render={({ field }) => (
                      <DataPickerComponent
                        label="Data Inicial"
                        placeHolder="DD/MM/YYYY"
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Controller
                    name="secondDate"
                    control={control}
                    render={({ field }) => (
                      <DataPickerComponent
                        label="Data Final"
                        placeHolder="DD/MM/YYYY"
                        {...field}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="confidential"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckboxComponent
                    label="Status de homologação"
                    description="Foi arquivado (Homologado)"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="search"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Pesquisar por conteúdo"
                    placeholder="Digite o assunto desejado..."
                    type="edit"
                    maxLength={100}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="search"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <SelectComponent
                    label="Tipo de associação"
                    placeholder="Selecione o tipo de associação..."
                    options={tiposRelatorio}
                    selectHelperText={error?.message}
                    type="type"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="search"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <InputComponent
                    label="Descrição de associação"
                    placeholder="Digite a associação..."
                    type="edit"
                    maxLength={100}
                    inputHelperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Box
              borderRadius={1}
              margin={1}
              padding={2}
              display="flex"
              alignItems="center"
              gap={2}
              sx={{ backgroundColor: theme.customColors.greyWarm100 }}
            >
              <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              <Typography variant="p">
                Preencha os critérios desejados e clique em ”Pesquisar”. Pelo
                menos um campo deve ser preenchido.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}
