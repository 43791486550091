import { useCallback } from "react";
import useSWR from "swr";
import GenericService from "../services/GenericService";

const useReports = (defaultSize = 5) => {
  // Criar um relatório
  const createReport = useCallback(async (payload) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await GenericService.create("reporting-order", payload);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error(response.data?.message || "Erro ao criar relatório");
    } catch (error) {
      throw error;
    }
  }, []);

  // Buscar um relatório por ID
  const findReportById = useCallback(async (id) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await GenericService.findOne("find-reporting", id);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error(response.data?.message || "Erro ao buscar relatório");
    } catch (error) {
      throw error;
    }
  }, []);

  // Listar relatórios com paginação
  const useListReports = (size = defaultSize) => {
    const { data, error, isValidating, mutate } = useSWR(
      `listall-report?size=${size}`,
      async (url) => {
        const response = await GenericService.findAllList(url);
        if (response.status === 200) {
          return response.data;
        }
        throw new Error(response.data?.message || "Erro ao listar relatórios");
      },
    );

    return {
      reports: data,
      isLoading: !error && !data,
      isError: error,
      isValidating,
      mutate,
    };
  };

  const useReportById = (id) => {
    const { data, error, isValidating, mutate } = useSWR(
      id ? `find-reporting/${id}` : null,
      async () => {
        const response = await GenericService.findOne("find-reporting", id);
        if (response.status === 200) {
          return response.data;
        }
        throw new Error(response.data?.message || "Erro ao buscar relatório");
      },
    );

    return {
      data,
      error,
      isLoading: !error && !data,
      isValidating,
      mutate,
    };
  };

  return {
    createReport,
    findReportById,
    useListReports,
    useReportById,
  };
};

export default useReports;
