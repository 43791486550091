import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoaderData, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TableComponent from "../../components/Table/TableComponent";
import TextArea from "../../components/TextArea/TextArea";
import InputComponent from "../../components/InputComponent/InputComponent";
import SelectComponent from "../../components/SelectComponent/SelectComponent";
import ModalEditAssociationReport from "./components/ModalEditAssociantionReport";
import ModalDeleteAssociationReport from "./components/ModalDeleteAssociationReport";
import GenericService from "../../services/GenericService";
import { formatDescription } from "./ReportDetails";
import { useSnackbar } from "../../context/SnackbarProvider";

export const associationDetailsLoader = async ({ params }) => {
  try {
    const responseReport = await GenericService.findOne(
      "find-reporting",
      params.id,
    );

    const responseAssociation = await GenericService.findAllList(
      `reporting/${params.id}/pointInterest`,
    );

    if (responseReport.status === 200 && responseAssociation) {
      const transformedContent = responseAssociation.data.content.map(
        (item) => ({
          id: item.id,
          observation: item.observation,
          indicM: item.indicM,
          indicP: item.indicP,
          type: item.pointInterest.type.type,
          typeDescription: item.pointInterest.typeDescription,
          pointInterestId: item.pointInterest.id,
          typeId: item.pointInterest.type,
        }),
      );
      const transformedAssociationData = {
        ...responseAssociation.data,
        content: transformedContent,
      };

      return {
        reportDetails: responseReport.data,
        associations: transformedAssociationData,
      };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error loading association details:", error);
  }
  return {};
};

export default function ResultSearchReport() {
  const { handleSubmit, control, watch, reset } = useForm({
    resolver: yupResolver(),
  });
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const { reportDetails } = useLoaderData();
  const [associations, setAssociations] = useState(
    useLoaderData().associations,
  );
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortInfo, setSortInfo] = useState({ id: null, type: null });

  const columns = [
    { id: "creationDate", label: "Data", date: true, isSort: true },
    { id: "id", label: "ID", isSort: true },
    { id: "title", label: "Título", isSort: true },
    { id: "team", label: "Equipe" },
    { id: "status", label: "Status" },
  ];

  const selectedType = watch("type");
  const typeSelected = selectedType
    ? typeOptions.find((option) => option.name === selectedType.name)
    : null;

  const handleReportView = () => {
    navigate(`/relatorios/${reportDetails.id}/editar-visualizar`);
  };

  const getAssociations = async (page = 0, size = pageSize) => {
    let query = `page=${page}&size=${size}`;
    if (sortInfo.id && sortInfo.type) {
      query += `&sortBy=${sortInfo.id}&order=${sortInfo.type}`;
    }

    try {
      const responseAssociation = await GenericService.findAllList(
        `reporting/${reportDetails.id}/pointInterest?${query}`,
      );

      if (responseAssociation) {
        const transformedContent = responseAssociation.data.content.map(
          (item) => ({
            id: item.id,
            observation: item.observation,
            indicM: item.indicM,
            indicP: item.indicP,
            type: item.pointInterest.type.type,
            typeDescription: item.pointInterest.typeDescription,
            pointInterestId: item.pointInterest.id,
            typeId: item.pointInterest.type,
          }),
        );
        const transformedAssociationData = {
          ...responseAssociation.data,
          content: transformedContent,
        };

        setAssociations(transformedAssociationData);
      }
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao carregar associações: ${error}`,
      });
    }
  };

  const handleAssociationFocus = (id) => {
    const associationFocus = associations.content.find(
      (association) => association.id === id,
    );
    setSelectedAssociation(associationFocus);
  };

  const handleChangePage = async (page) => {
    setCurrentPage(page);
    await getAssociations(page, pageSize);
  };

  const handleChangeSize = async (size) => {
    setPageSize(size);
    setCurrentPage(0); // Quando alterar o tamanho da página, resetar para a primeira página
    await getAssociations(0, size);
  };

  useEffect(() => {
    getAssociations(currentPage, pageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortInfo]);

  return (
    <Box>
      <Box marginY={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <Typography variant="h4">Resultados da Pesquisa</Typography>
          <Box display="flex" gap={2}>
            <ButtonComponent
              title="Refazer pesquisa"
              icon="reload"
              onClick={() => handleReportView()}
            />
          </Box>
        </Box>
      </Box>
      <Grid container display="flex" gap={3}>
        <TableComponent
          columns={columns}
          actions={["info"]}
          data={associations}
          changeSize={handleChangeSize}
          changePage={handleChangePage}
          {...(reportDetails?.status === "Em elaboração" && {
            actions: ["edit", "delete"],
            onClickEdit: (data) => {
              handleAssociationFocus(data);
              setOpenModalEdit(true);
            },
            onClickDelete: (data) => {
              handleAssociationFocus(data);
              setOpenModalDelete(true);
            },
          })}
        />
      </Grid>
    </Box>
  );
}
