import * as React from "react";

export default function AutocompleteV2(props) {
  const options = props.options || [];
  const value = props.value || props.defaultValue;
  const sx = props.sx || {};
  const handleChange = (event) => {
    if (props.onInputChange) {
      props.onInputChange(event, event.target.value);
    }
  };

  return (
    <select
      title="Ola mundo"
      style={{
        ...sx,
        backgroundColor: "#fff",
        border: "1px solid #888888",
        borderRadius: "4px",
        paddingLeft: "2px",
      }}
      value={value}
      onChange={handleChange}
    >
      {options.map((r) => (
        <option value={r}>{r}</option>
      ))}
    </select>
  );
}
