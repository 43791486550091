import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TableComponent from "../../components/Table/TableComponent";
import { getUserInfoFromToken } from "../../services/Auth";
import { useSnackbar } from "../../context/SnackbarProvider";
import ModalDeleteBalanceHour from "./components/ModalDeleteBalanceHour";
import ModalEditBalanceHour from "./components/ModalEditBalanceHour";
import ModalRegisterBalanceHour from "./components/ModalRegisterBalanceHour";
import useHourBalance from "../../hooks/useHourBalance";
import useReports from "../../hooks/useReports";

const newAddonFormValidationSchema = yup.object({
  description: yup
    .string()
    .required("O campo ”observação” não pode ficar vazio"),
});

export default function BalanceHours() {
  const { id: orderId } = useParams();
  const userInfo = getUserInfoFromToken();
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(newAddonFormValidationSchema),
    defaultValues: {
      description: "",
    },
  });
  const navigate = useNavigate();

  const {
    createHourBalance,
    useGetHourBalanceWithRequester,
    useGetHourBalance,
    deleteHourBalance,
  } = useHourBalance();
  const {
    hourBalanceAll,
    isLoadingHourBalanceAll,
    isErrorHourBalanceAll,
    mutateHourBalanceAll,
  } = useGetHourBalanceWithRequester(orderId, currentPage, pageSize);
  const {
    hourBalanceUser,
    isLoadingHourBalanceUser,
    isErrorHourBalanceUser,
    mutateHourBalanceUser,
  } = useGetHourBalance(orderId, currentPage, pageSize);
  const {
    data: reportDetails,
    error,
    isLoading: isLoadingReport,
  } = useReports().useReportById(orderId);
  const [sortInfo, setSortInfo] = useState({ id: "", type: "" });
  const [modalRegister, setModalRegister] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [hourBalanceSelected, setHourBalanceSelected] = useState();
  const { showSnackbar } = useSnackbar();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState("panel-1");

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setPageSize(5);
    setCurrentPage(0);
  };

  const handleChangePage = async (page, table) => {
    setCurrentPage(page);
    if (table === "user") {
      await mutateHourBalanceUser();
    } else if (table === "all") {
      await mutateHourBalanceAll();
    }
  };

  const handleChangeSize = async (size, table) => {
    setPageSize(size);
    setCurrentPage(0);

    if (table === "user") {
      mutateHourBalanceUser();
    } else if (table === "all") {
      mutateHourBalanceAll();
    }
  };

  const columns = [
    { id: "day", label: "Data", date: true },
    { id: "userName", label: "Usuário", big: true },
    { id: "timeExpended", label: "Saldo", orderRight: true, time: true },
  ];

  const handleHourSelectedFocus = (id) => {
    const hourBalanceFocus = hourBalance.content.find(
      (hourBalanceContent) => hourBalanceContent.id === id,
    );
    setHourBalanceSelected(hourBalanceFocus);
  };

  const handleDeleteHourBalance = async () => {
    try {
      const result = await deleteHourBalance(hourBalanceSelected.id);
      if (result) {
        showSnackbar({
          success: true,
          message: "Saldo de horas deletado com sucesso!",
        });
        setModalDelete(false);
        mutateHourBalanceUserAll();
      }
      // eslint-disable-next-line no-shadow
    } catch (error) {
      showSnackbar({
        error: true,
        message: `Erro ao deletar o saldo de horas: ${error}`,
      });
    }
  };

  // console.log(hourBalanceUser, hourBalanceAll);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="h4" marginY={5}>
          {reportDetails?.title}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={2}
      >
        <Box display="flex" flexDirection="column" gap={2} width="65%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="p7">Consultar saldo de horas</Typography>
            <ButtonComponent
              title="Registrar horas"
              icon="new"
              onClick={() => setModalRegister(true)}
            />
          </Box>

          <Box className="br-tab">
            {userInfo?.roles?.includes("supervisor") && (
              <Box className="tab-nav">
                <ul>
                  <li
                    className={`tab-item ${
                      activeTab === "panel-1" ? "is-active" : ""
                    }`}
                    title="Meu saldo de horas"
                  >
                    <button
                      type="button"
                      onClick={() => handleTabChange("panel-1")}
                      data-panel="panel-1"
                    >
                      <span className="name">Meu saldo de horas</span>
                    </button>
                  </li>

                  <li
                    className={`tab-item ${
                      activeTab === "panel-2" ? "is-active" : ""
                    }`}
                    title="Todos"
                  >
                    <button
                      type="button"
                      onClick={() => handleTabChange("panel-2")}
                      data-panel="panel-2"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span className="name">Todos</span>
                    </button>
                  </li>
                </ul>
              </Box>
            )}

            <Box className="tab-content">
              <div
                className={`tab-panel ${
                  activeTab === "panel-1" ? "active" : ""
                }`}
                id="panel-1"
              >
                <TableComponent
                  columns={columns}
                  data={hourBalanceUser}
                  changeSize={(size) => handleChangeSize(size, "user")}
                  changePage={(page) => handleChangePage(page, "user")}
                  onClickDelete={(data) => {
                    handleHourSelectedFocus(data);
                    setModalDelete(true);
                  }}
                  onClickEdit={() => setModalEdit(true)}
                  actions={["edit", "delete"]}
                />
              </div>
              {userInfo?.roles?.includes("supervisor") && (
                <div
                  className={`tab-panel ${
                    activeTab === "panel-2" ? "active" : ""
                  }`}
                  id="panel-2"
                >
                  <TableComponent
                    columns={columns}
                    data={hourBalanceAll}
                    changeSize={(size) => handleChangeSize(size, "all")}
                    changePage={(page) => handleChangePage(page, "all")}
                    onClickDelete={(data) => {
                      handleHourSelectedFocus(data);
                      setModalDelete(true);
                    }}
                    onClickEdit={() => setModalEdit(true)}
                    actions={["edit", "delete"]}
                  />
                </div>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          width="35%"
          borderRadius={1}
          boxShadow={3}
          padding={2}
        >
          <Typography variant="h2">Observação</Typography>
          <Box
            borderRadius={1}
            padding={2}
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ backgroundColor: theme.customColors.greyWarm100 }}
          >
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
            <Typography variant="p">
              Apenas o supervisor pode visualizar o saldo de horas de todos os
              usuários que editaram o relatório.
            </Typography>
          </Box>
        </Box>
      </Box>
      <ModalDeleteBalanceHour
        openModal={modalDelete}
        closeModal={() => setModalDelete(false)}
        handleDelete={() => handleDeleteHourBalance()}
        hourBalance={hourBalanceSelected}
      />
      <ModalEditBalanceHour
        openModal={modalEdit}
        closeModal={() => setModalEdit(false)}
        onFormSubmit={() => setModalEdit(false)}
      />
      <ModalRegisterBalanceHour
        openModal={modalRegister}
        closeModal={() => setModalRegister(false)}
        onFormSubmit={() => setModalRegister(false)}
      />
    </Box>
  );
}
