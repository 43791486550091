import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Modal } from "antd";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DataPickerComponent from "../DataPickerComponent/DataPickerComponent";
import InputComponent from "../InputComponent/InputComponent";
import SelectComponent from "../SelectComponent/SelectComponent";
import "./style.css";
import { getUserInfoFromToken } from "../../services/Auth";
import CheckboxComponent from "../CheackboxComponent/CheckboxComponent";

const newFilterFormValidationSchema = yup.object({
  author: yup.object(),
  typeOff: yup.object(),
  supervisor: yup.object(),
  textTitle: yup.string(),
  teamFilter: yup.object(),
  statusFilter: yup.object(),
  firstDate: yup.date(),
  secondDate: yup.date(),
  archived: yup.boolean(),
});

export default function ModalFilter({
  openModal,
  closeModal,
  onFormSubmit,
  dataModal,
  hideStatus,
}) {
  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(newFilterFormValidationSchema),
  });

  const userInfo = getUserInfoFromToken();
  const userRoles = userInfo ? userInfo.roles : [];
  const hasRequiredRoleSupervisor = userRoles?.some((role) =>
    ["supervisor"].includes(role),
  );
  const hasRequiredRoleAuthor = userRoles?.some((role) =>
    ["pesquisador"].includes(role),
  );

  const handleClearFilters = () => {
    reset();
  };

  function formatDate(dateObj) {
    if (dateObj instanceof Date) {
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return dateObj;
  }

  function handleAplicar(data) {
    const formattedData = Object.fromEntries(
      Object.entries(data)
        .map(([key, value]) => {
          if (value instanceof Date) {
            return [key, formatDate(value)];
          }
          return [key, value];
        })
        .filter(([_, value]) => value !== ""),
    );

    onFormSubmit(formattedData);
    closeModal();
  }

  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>Filtrar</span>
      }
      open={openModal}
      width={1114}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <form onSubmit={handleSubmit(handleAplicar)}>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={4}>
            <Controller
              name="typeOff"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  label="Tipo"
                  placeholder="Selecionar..."
                  type="type"
                  options={dataModal.typesReport}
                  {...field}
                />
              )}
            />
          </Grid>
          {!hasRequiredRoleAuthor && (
            <Grid item xs={4}>
              <Controller
                name="author"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    label="Pesquisador"
                    placeholder="Digite o nome do pesquisador..."
                    type="user"
                    disableArrow
                    options={dataModal.authors}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          {!hideStatus && ( // Condição para esconder o status
            <Grid item xs={4}>
              <Controller
                name="statusFilter"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    label="Status"
                    placeholder="Status..."
                    type="status"
                    options={dataModal.status}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Controller
              name="textTitle"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputComponent
                  label="Título"
                  placeholder="Digite o nome do título..."
                  type="edit"
                  {...field}
                />
              )}
            />
          </Grid>
          {!hasRequiredRoleSupervisor && (
            <Grid item xs={4}>
              <Controller
                name="supervisor"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    label="Supervisor"
                    placeholder="Digite o nome do supervisor..."
                    type="supervisor"
                    disableArrow
                    options={dataModal.supervisors}
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Controller
              name="teamFilter"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  label="Equipe"
                  placeholder="Selecionar..."
                  type="users"
                  options={dataModal.teams}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="space-between">
              <Box mr={1} flexGrow={1}>
                <Controller
                  name="firstDate"
                  control={control}
                  render={({ field }) => (
                    <DataPickerComponent
                      label="Data Inicial"
                      placeHolder="DD/MM/YYYY"
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box flexGrow={1}>
                <Controller
                  name="secondDate"
                  control={control}
                  render={({ field }) => (
                    <DataPickerComponent
                      label="Data Final"
                      placeHolder="DD/MM/YYYY"
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="archived"
              control={control}
              render={({ field }) => (
                <CheckboxComponent
                  label="Incluir arquivados"
                  onChange={field.onChange}
                  checked={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              <Button
                variant="outlined"
                sx={{
                  width: 153,
                  height: 32,
                  borderRadius: 8,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                onClick={handleClearFilters}
              >
                Limpar filtros
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: 105,
                  height: 32,
                  borderRadius: 8,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                type="submit"
              >
                Aplicar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
}
