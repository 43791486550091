import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import {
  faExpand,
  faImage,
  faEye,
  faEraser,
  faCompress,
} from "@fortawesome/free-solid-svg-icons";
import {
  // Box,
  Popover,
  Tooltip,
  // Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useOutletContext } from "react-router-dom";
import FontColorToolbarEditor from "./FontColorToolbarEditor";
import Heading from "./Toolbar/Heading";
import Font from "./Toolbar/Font";
import Alignment from "./Toolbar/Alignment";
import TextStyles from "./Toolbar/TextStyles";
import List from "./Toolbar/List";
import Illustration from "./Toolbar/Illustration";
import History from "./Toolbar/HIstory";
import ButtonCopy from "./Toolbar/ButtonCopy";

function ToolbarEditor(props) {
  const {
    editor,
    setPreview,
    preview,
    content,
    status,
    enlargeEditor,
    setEnlargeEditor,
  } = props;

  const [fontColor, setFontColor] = useState({ color: "#000000" });
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const handleClose = () => {
    isPopoverCliked = false;
    setAnchorEl(null);
  };

  const handlePreview = () => {
    setPreview(!preview);
  };

  const handleEnlarge = () => {
    setEnlargeEditor((prevState) => !prevState);
  };

  const clearFormatter = () => {
    if (!editor) return;
    editor.chain().focus().clearNodes().unsetAllMarks().run();
  };
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "20px",
        alignItems: "flex-end",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <Heading editor={editor} />
        <Font editor={editor} />

        <Popover
          id={popoverId}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <FontColorToolbarEditor
            editor={editor}
            src="toolbar"
            fontColor={fontColor}
            setFontColor={setFontColor}
          />
        </Popover>

        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mx: 1,
            my: 1,
            marginBottom: 0,
            marginTop: 4,
            marginLeft: 0,
            marginRight: 0,
          }}
        />
        <Alignment editor={editor} />

        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mx: 1,
            my: 1,
            marginBottom: 0,
            marginTop: 4,
            marginLeft: 0,
            marginRight: 0,
          }}
        />
        <TextStyles editor={editor} content={content} />

        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mx: 1,
            my: 1,
            marginBottom: 0,
            marginTop: 4,
            marginLeft: 0,
            marginRight: 0,
          }}
        />
        <List editor={editor} />

        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mx: 1,
            my: 1,
            marginBottom: 0,
            marginTop: 4,
            marginLeft: 0,
            marginRight: 0,
          }}
        />
        <Illustration editor={editor} icon={faImage} />
        <Tooltip placement="top" title="Limpar formatação">
          <ToggleButton
            onClick={clearFormatter}
            sx={{
              width: 40,
              height: 40,
              marginRight: 1,
              borderColor: "#888",
            }}
            value="Limpar formatação"
          >
            <FontAwesomeIcon icon={faEraser} />
          </ToggleButton>
        </Tooltip>
        <ButtonCopy editor={editor} />
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            mx: 1,
            my: 1,
            marginBottom: 0,
            marginTop: 4,
            marginLeft: 0,
            marginRight: 0,
          }}
        />
        <History editor={editor} />
        <ToggleButton
          value={preview}
          selected={preview}
          onClick={handlePreview}
          disabled={
            status === "Homologado" || status === "Aguardando Homologação"
          }
          sx={{
            width: 98,
            height: 40,
            marginRight: 1,
            "&.Mui-selected, &.Mui-selected:hover": {
              color: "white",
              backgroundColor: "#888888",
            },
          }}
        >
          <FontAwesomeIcon icon={faEye} />
          <div style={{ textTransform: "capitalize", fontSize: 16.8 }}>
            &nbsp;Prévia
          </div>
        </ToggleButton>
      </div>

      <div>
        <ToggleButton
          value={enlargeEditor}
          selected={enlargeEditor}
          onClick={handleEnlarge}
          sx={{
            width: 200,
            height: 40,
            "&.Mui-selected, &.Mui-selected:hover": {
              color: "white",
              backgroundColor: "#888888",
            },
          }}
        >
          {enlargeEditor ? (
            <>
              <FontAwesomeIcon icon={faCompress} />
              <div style={{ textTransform: "capitalize", fontSize: 16.8 }}>
                &nbsp;Diminuir Editor
              </div>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faExpand} />
              <div style={{ textTransform: "capitalize", fontSize: 16.8 }}>
                &nbsp;Ampliar Editor
              </div>
            </>
          )}
        </ToggleButton>
      </div>
    </Paper>
  );
}

export default ToolbarEditor;
