import authApi from "./AuthApi";

export default class GenericService {
  static BASE_URL = window.location.origin.includes("localhost")
    ? "/api"
    : `${window.location.origin}/api-core/api`;

  static async findAll(route, params) {
    try {
      const queryString = params.join("&");
      return await authApi.get(`${this.BASE_URL}/${route}?${queryString}`);
    } catch (e) {
      return e.response;
    }
  }

  static async findAllList(list, header) {
    try {
      return await authApi.get(`${this.BASE_URL}/${list}`, { headers: header });
    } catch (e) {
      return e.response;
    }
  }

  static async findAllListById(ids) {
    try {
      return await authApi.get(
        `${this.BASE_URL}/list/ids?${ids.map((id) => `ids=${id}`).join("&")}`,
      );
    } catch (e) {
      return e.response;
    }
  }

  static async filter(params) {
    try {
      return await authApi.get(`${this.BASE_URL}/filter`, { params });
    } catch (e) {
      return e.response;
    }
  }

  static async findOne(route, id, headers = {}, params = {}) {
    try {
      const queryString = new URLSearchParams(params).toString();
      const url = `${this.BASE_URL}/${route}/${id}${queryString ? `?${queryString}` : ""}`;
      return await authApi.get(url, { headers });
    } catch (e) {
      return e.response;
    }
  }

  static async create(route, entity, headers) {
    try {
      return await authApi.post(`${this.BASE_URL}/${route}`, entity, {
        headers,
      });
    } catch (e) {
      return e.response;
    }
  }

  static async update(route, entity) {
    try {
      return await authApi.put(`${this.BASE_URL}/${route}`, entity);
    } catch (e) {
      return e.response;
    }
  }

  static async deleteOne(id) {
    try {
      return await authApi.delete(`${this.BASE_URL}/${id}`);
    } catch (e) {
      return e.response;
    }
  }

  static async deleteAll(ids) {
    try {
      return await authApi.delete(
        `${this.BASE_URL}?${ids.map((id) => `ids=${id}`).join("&")}`,
      );
    } catch (e) {
      return e.response;
    }
  }

  static async getPDFRender(route, entity, headers) {
    try {
      return await authApi.post(
        `${process.env.REACT_APP_API_STAGING}/${route}`,
        entity,
        {
          headers,
        },
      );
    } catch (e) {
      return e.response;
    }
  }

  static async saveDocument(url, idReport, content, header) {
    try {
      if (this.validateJSON(content)) {
        return await authApi.put(
          `${this.BASE_URL}/${url}/${idReport}`,
          content,
          { headers: header },
        );
      }
      return null;
    } catch (e) {
      return e.response;
    }
  }

  static async getDocument(url) {
    try {
      return await authApi.get(`${this.BASE_URL}/${url}`);
    } catch (e) {
      return e.response;
    }
  }

  static validateJSON(json) {
    return !!JSON.parse(JSON.stringify(json));
  }

  static async renderPDF(route, params, headers) {
    try {
      const baseUrl = window.location.origin.includes("localhost")
        ? "https://mi-sigar.pti.foz.br"
        : window.location.origin;

      return await authApi.post(`${baseUrl}/${route}`, params, {
        headers,
        responseType: "arraybuffer",
      });
    } catch (e) {
      return e.response;
    }
  }

  static async login(data) {
    try {
      const loginUrl = window.location.origin.includes("localhost")
        ? "https://mi-sigar.pti.foz.br/api/login"
        : `${window.location.origin}/api/login`;

      const response = await authApi.post(loginUrl, {
        username: data.username.replace(/\D/g, ""),
        password: data.password,
      });

      if (response.data && response.data.accessToken) {
        return response.data;
      }

      if (response.status === 403) {
        return { error: "Forbidden" };
      }

      return null;
    } catch (error) {
      return { error: error.message || "Login failed" };
    }
  }
}
