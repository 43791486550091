import * as React from "react";

export default function TextFieldV2(props) {
  const sx = props.sx || {};
  const { value } = props;
  const handleNumber = (event) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <input
      type="number"
      style={{
        ...sx,
        backgroundColor: "#fff",
        border: "1px solid #888888",
        borderRadius: "4px",
        paddingLeft: "4px",
        paddingRight: "4px",
      }}
      onChange={handleNumber}
      value={value}
      min={0}
    />
  );
}
