import React from "react";
import { format, isValid, parseISO } from "date-fns";
import { Box, Typography } from "@mui/material";
import { Modal } from "antd";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import "./style.css";

function formatDate(dateString) {
  if (!dateString) return "01/01/1900"; // Valor padrão
  const date = parseISO(dateString); // Tenta parsear a data
  if (!isValid(date)) return "01/01/1900"; // Retorna padrão se inválida
  return format(date, "dd/MM/yyyy");
}

// function removeSecondsFromTime(time) {
//   const parts = time.split(":");
//   return `${parts[0]}:${parts[1]}`;
// }

export default function ModalDeleteBalanceHour({
  openModal,
  closeModal,
  handleDelete,
  hourBalance,
}) {
  return (
    <Modal
      title={
        <span style={{ fontFamily: "Rawline", fontWeight: 600 }}>
          Excluir registro
        </span>
      }
      open={openModal}
      width={550}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <Box
        display="flex"
        flexDirection="column"
        marginTop={3}
        gap={1}
        // style={{ fontFamily: "Rawline" }}
      >
        <Typography variant="p">
          O seguinte registro de horas será excluído permanentemente:
        </Typography>
        <Box>
          <Box display="flex" gap={1}>
            <Typography variant="p6">Data: </Typography>
            <Typography variant="p">{formatDate(hourBalance?.day)}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="p6">Saldo: </Typography>
            {/* <Typography variant="p"> */}
            {/*  {removeSecondsFromTime(hourBalance?.timeExpended)} */}
            {/* </Typography> */}
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="p6">Usuário: </Typography>
            <Typography variant="p">
              {hourBalance?.userName || `Usuário`}
            </Typography>
          </Box>
        </Box>
        <Typography variant="p">
          Essa ação é permanente e não pode ser desfeita. Deseja prosseguir com
          a exclusão?
        </Typography>
        <Box display="flex" justifyContent="flex-end" marginTop={1} gap={2}>
          <ButtonComponent
            buttonType="secondary"
            title="Cancelar"
            onClick={closeModal}
          />
          <ButtonComponent
            title="Excluir"
            icon="delete"
            deleteColor
            onClick={() => handleDelete()}
          />
        </Box>
      </Box>
    </Modal>
  );
}
